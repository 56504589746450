<template>
  <div :class="{ home: true }">
    <!-- 轮播图 -->
    <swiperIndex :height="swierHeight" :imgArr="imgArr" v-cloak></swiperIndex>
    <!-- 内容区域 -->
    <el-row>
      <!-- 文章列表 文章列表响应式 -->
      <el-col
        class="article clearfix"
        :xs="24"
        :sm="24"
        :md="24"
        :lg="16"
        :xl="16"
      >
        <div class="article_top">
          <div class="article_top_left">全部文章</div>
          <div class="article_top_right">
            <span>列表形式</span>
            <el-switch v-model="autoFlag" />
          </div>
        </div>
        <div class="clearfix1">
          <!-- 文章 -->
          <div
            v-for="item in posts"
            :key="item.id"
            :style="{
              padding: '10px 0px',
            }"
          >
            <!-- 小列表 -->
            <ArticleSmall
              v-seen="{ type: 'animate', class: 'animate__fadeInUp' }"
              :imgWidth="flexScreen != 'mob' ? '200px' : '100px'"
              :imgHeight="flexScreen != 'mob' ? '120px' : '60px'"
              :titleSize="flexScreen != 'mob' ? '20px' : '16px'"
              v-show="autoFlag"
              class="clearfix"
              :article="item"
            >
              <template v-if="flexScreen != 'mob'" #description>
                <div class="description">
                  {{ item.shortContent }}
                </div>
              </template>
              <template #btn>
                <el-button
                  class="ArticleSmall_btn"
                  type="text"
                  size="small"
                  :icon="Right"
                  @click="toPosts({ id: item.id })"
                >
                </el-button>
              </template>
            </ArticleSmall>
            <!-- 大列表 -->
            <ArticleList v-show="!autoFlag" class="clearfix" :article="item">
            </ArticleList>
          </div>
        </div>

        <div class="loadMoreHome" v-show="moreFlag && scrollFlag">
          <el-button
            size="small"
            type="default"
            @click="getMore(0)"
            :loading="moreLoading"
          >
            加载更多
          </el-button>
        </div>
        <div class="without" v-show="!moreFlag">-- 已经到底了 --</div>
      </el-col>
      <!-- 组件区 -->
      <el-col class="components" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <HotTools
          :articleList="componentData.HotsPosts"
          :replyList="componentData.NewReply"
        ></HotTools>
        <!-- 标签区 -->
        <tags :tagsList="tagsList"></tags>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Right } from "@element-plus/icons";

import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import {
  ref,
  reactive,
  onMounted,
  onActivated,
  computed,
  watch,
  nextTick,
} from "vue";
import ArticleSmall from "@/components/articleList/articleSmall";
import swiperIndex from "../../components/swiperIndex/swiperIndex.vue";
import ArticleList from "../../components/articleList/articleList.vue";
import tags from "../../components/tags/tags.vue";
import usePosts from "./usePosts.js";
import useImgArr from "./useImgArr.js";
import useTags from "./useTags.js";
import useScroll from "./useScroll.js";
import useLoading from "../../hooks/useLoading.js";
import HotTools from "./components/hotTools.vue";
import useScreen from "@/hooks/useScreen.js";
import { useHead } from "@vueuse/head";

export default {
  name: "home",
  components: {
    swiperIndex,
    ArticleList,
    tags,
    HotTools,
    ArticleSmall,
  },
  setup(props, context) {
    let loading = useLoading(); //动画开始
    useScroll(loading); //记录滚动条hook
    let store = useStore();
    let router = useRouter();
    let route = useRoute();
    store.commit("headInfo/UP_ACTIVE_LINK", "首页");
    let posts = usePosts(0); //关于文章列表的hook
    let imgArr = useImgArr(); //关于轮播的hook
    let tagsList = useTags(); //关于标签hook
    let { flexScreen } = useScreen();
    window.scrollTo(0, 0);


    // 去文章详情页
    let toPosts = (params) => {
      router.push({
        name: "posts",
        params: { ...params },
      });
    };

    onActivated(() => {
      store.commit("headInfo/UP_ACTIVE_LINK", "首页");

      useHead({
        // Can be static or computed
        title: "Baymax-首页",
        meta: [
          {
            name: `description`,
            content:
              "Baymax是一个前端踩坑记录、日常生活分享、技术分享、博客搬运的基于vue3.x的个人博客",
          },
          {
            name: `keywords`,
            content:
              "Baymax,技术分享,踩坑记录,博客搬运,vue3，一个基于vue3个人博客",
          },
        ],
      });
    });

    return {
      Right,
      flexScreen,
      toPosts,
      ...imgArr,
      ...posts,
      ...tagsList,
    };
  },
};
</script>

<style lang="scss" scoped >
.description {
  background-color: var(--white_divBgc) !important;
  color: var(--white_divFc) !important;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
}
.ArticleSmall_btn {
  font-size: 18px !important;
  color: var(--white_divFc_light) !important;
}
.ArticleSmall {
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px 0;
}
.loadMoreHome {
  margin-top: 5px;
  width: 100%;
  .el-button {
    padding: 8px !important;
    background-color: var(--white_divBgc) !important;
    color: var(--white_divFc) !important ;
    border: 1px solid var(--white_divBgc) !important;
  }
}

.without {
  padding: 10px 0;
  color: var(--white_divFc);
  font-size: 14px;
}
.clearFix::after {
  content: " ";
  height: 0;
  line-height: 0;
  display: block;
  clear: both;
  visibility: hidden;
}
.article_top_left {
  flex: 1;
  color: var(--white_divFc);
  font-size: 18px;
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.article_top_right {
  flex: 1;
  color: var(--white_divFc);
  font-size: 18px;
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.article_top_right span {
  margin-right: 5px;
}

.article_top {
  width: 100%;
  display: flex;
}

.article {
  /* position: relative; */
  /* border: 1px solid #999; */
}

.components {
  height: auto;
  /* border: 1px solid #999; */
  border-left: none;
}

.home {
  width: 55%;
  margin: 0 auto;
  height: auto;
  border-top: none;
  padding: 20px 40px;
  min-width: 600px;
  z-index: 0;
}

@media screen and (max-width: 768px) {
  .ArticleSmall {
    padding: 0 !important;
    border-radius: 5px;
    border: none;
  }
  .home {
    width: 90%;
    margin: 0 auto;
    height: auto;
    border-top: none;
    padding: 10px;
    min-width: 300px;
    z-index: 1;
  }
}
</style>
