/*
 * @Author: your name
 * @Date: 2022-03-15 22:30:14
 * @LastEditTime: 2022-04-03 17:58:47
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\pages\home\useImgArr.js
 */
import { ref, onMounted, watch, computed } from 'vue'
import { getScreenWidth } from '../../utils/public'
import useScreen from '@/hooks/useScreen'
import { getLikedPost } from '../../api/api.js'

export default function () {
  //接收轮播图
  let imgArr = ref([
    {
      src: '',
    },
  ])

  // 监听屏幕宽度设置轮播图高度
  // watch(screenWidth, (n, o) => {
  // 	swierHeight.value = n <= 600 ? 600 : 750
  // }, {
  // 	immediate: true
  // })

  let { flexScreen ,screenWidth} = useScreen()
  // 轮播图高度
  let swierHeight = computed(() => {
    switch (flexScreen.value) {
      case 'mob':
        return 500
      default:
        return 700
    }
  })
  onMounted(async () => {
    let { data } = await getLikedPost() //获取轮播
    imgArr.value = data
    console.log('imgArr');

  })
  return {
    imgArr,
    swierHeight,
		screenWidth
  }
}
