<template>
  <div class="top" v-if="!isPhoto">
    <div class="left">猜你喜欢</div>
    <div class="right">
      <span>自动播放</span>
      <el-switch v-model="autoFlag" />
    </div>
  </div>
  <el-carousel
    v-if="imgArr.length"
    :interval="3000"
    :height="height + 'px'"
    :initial-index="0"
    ref="Carousel"
    :autoplay="autoFlag"
    arrow="always"
  >
    <el-carousel-item v-for="(item, index) in imgArr" :key="index">
      <div
        class="carousel-bg"
        @click="!isPhoto ? null : preview(index, imgArr, 'src')"
        :style="{ 'background-image': `url(${item.src})` }"
      >
        <div class="carousel-modal">
          <div v-if="!isPhoto" class="carousel-div">
            <div class="carousel-title">
              {{ item.title }}
            </div>
            <div class="carousel-read">
              <el-button size="small" type="danger" @click="toPosts(item.id)">
                马上阅读
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- <el-image :src="item.src" fit="fill"></el-image> -->
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import { ref, toRefs, computed, onMounted, watch } from "vue";
import { preview } from "vue3-preview-image";
import { useRouter } from "vue-router";

export default {
  name: "swiperIndex",
  props: {
    height: {
      type: Number,
      default: 600,
    },
    imgArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isPhoto: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    let router = useRouter();
    let autoFlag = ref(true);
    let { height, imgArr } = toRefs(props);

    let toPosts = (id) => {
      router.push({
        path: `/posts/${id}`,
      });
    };

    return {
      autoFlag,
      height,
      imgArr,
      preview,
      toPosts,
    };
  },
};
</script>

<style>
.top {
  display: flex;
  height: 30px;
  padding: 5px 0 10px 0;
}

.left {
  flex: 1;
  text-align: left;
  font-family: "UniNeue" !important;
  color: var(--white_divFc);
}

.right {
  flex: 1;
  text-align: right;
  font-family: "UniNeue" !important;
  color: var(--white_divFc);
}

.right span {
  margin: 5px;
}

.carousel-read {
  margin-top: 10px;
}

.carousel-bg {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  background: bottom center no-repeat;
  background-size: auto 100%;
  position: relative;
}

.carousel-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.carousel-div {
  width: 100%;
  height: 100px;
  margin: 0 auto;
  position: absolute;
  bottom: 100px;
  padding: 10px 40px;
  box-sizing: border-box;
}

.carousel-title {
  font-weight: 600;
  font-size: 28px;
  color: #eee;
}

.el-carousel {
  border-radius: 15px;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  border-radius: 15px;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
  border-radius: 15px;
}
@media screen and (max-width: 768px) {
  .carousel-div {
    padding: 10px 20px;
    bottom: 80px;
  }
  .carousel-title {
    text-align: justify;
    font-weight: 600;
    font-size: 24px;
    color: #eee;
  }
}
</style>
